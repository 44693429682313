import React, { PureComponent, getGlobal, setGlobal } from "reactn";
import Scrollbar from "react-scrollbars-custom";
import clientStorage from "../classes/clientStorage";
import logo from "../images/tchat_logo_dark.svg";
import logo_light from "../images/tchat_logo_light.svg";

import googleplay_badge from "../images/google-play-badge.svg";
import windows_badge from "../images/windows-badge.svg";

import googleplay_badge_light from "../images/google-play-badge-light.svg";
import windows_badge_light from "../images/windows-badge-light.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudUploadAlt, faTrash } from "@fortawesome/free-solid-svg-icons";

import RegistrationModal from "../components/RegistrationModal";
import DataModificationModal from "../components/DataModificationModal";
import UploadProfileImageModal from "../components/UploadProfileImageModal";
import DeleteProfileModal from "../components/DeleteProfileModal";
import ThemeSwitcher from "../components/ThemeSwitcher";

import axios from "axios";
import { Circle } from "react-preloaders";



class Login extends PureComponent {
  constructor(props) {
    super(props);
    this.client = clientStorage.id;
    this.connect = this.connect.bind(this);
    this.formChange = this.formChange.bind(this);
    this.connected = this.props.connected;
    this.apiUrl = this.global.apiUrl;
    this.uploadCoverImage = this.uploadCoverImage.bind(this);
    this.deleteCoverImage = this.deleteCoverImage.bind(this);

    this.state = {
      logintext: "Bejelentkezés",
      username: this.global.username,
      password: "",
      formDisabled: "",
      user: {
        name: null,
        email: null,
        hero: null,
        accept: true,
      },
      fruit: null,
      fruits: ["Férfi", "Nő", "Semleges"],
      selectedFile: null,
      profileImageLoaded: true,
    };
  }

  formChange(event, name) {
    if (name === "username") {
      localStorage.setItem("username", event.target.value);
      this.setState({
        username: event.target.value,
      });
    } else if (name === "password") {
      this.setState({
        password: event.target.value,
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    var state = {};
    if (this.props.connected !== this.connected) {
      if (this.client.connected === true) {
        state = {
          formDisabled: "disabled",
          logintext: "Kijelentkezés",
        };
        this.setState(state);
      } else {
        state = {
          formDisabled: "",
          logintext: "Bejelentkezés",
        };
        this.setState(state);
      }
      this.connected = this.client.connected;
    }
  }

  uploadCoverImage(event) {
    event.preventDefault();
    try {
      let file = event.target.files[0];
      let fd = new FormData();
      fd.append("uploadCoverImage", true);
      fd.append("sid", this.global.sid);
      fd.append("image", file, file.name);
      fd.append("method", "upload");

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      this.setState({ profileImageLoaded: false });
      axios
        .post(
          this.apiUrl + "/uploadCoverImage",
          fd,
          { headers },
          {
            onUploadProgress: (progressEvent) => {},
          }
        )
        .then((res) => {
          if (res.data.uploadCoverImage === "success") {
            this.client.sendMessage("reloadProfileImage");
          } else if (res.data.uploadCoverImage === "error") {
            this.client.sendAlert("Hiba!", res.data.message);
          }
          this.setState({ profileImageLoaded: true });
        });
    } catch (err) {
      console.log("uploadCoverImage: " + err.message);
    }
  }

  deleteCoverImage(event) {
    event.preventDefault();
    try {
      let fd = new FormData();
      fd.append("uploadCoverImage", true);
      fd.append("sid", this.global.sid);
      fd.append("method", "delete");

      const headers = {
        "Content-Type": "multipart/form-data",
      };

      this.setState({ profileImageLoaded: false });
      axios
        .post(
          this.apiUrl + "/uploadCoverImage",
          fd,
          { headers },
          {
            onUploadProgress: (progressEvent) => {},
          }
        )
        .then((res) => {
          if (res.data.uploadCoverImage === "success") {
            this.client.sendMessage("reloadProfileImage");
          } else if (res.data.uploadCoverImage === "error") {
            this.client.sendAlert("Hiba!", res.data.message);
          }
          this.setState({ profileImageLoaded: true });
        });
    } catch (err) {
      console.log("deleteCoverImage: " + err.message);
    }
  }

  connect() {
    if (this.client.connected === false) {
      if (getGlobal().sid === 0) {
        if (this.state.username !== "" && this.state.password !== "") {
          this.loginWithAPI(this.state.username, this.state.password);
        } else {
          this.client.sendAlert(
            "Nem adat meg a neved vagy jelszavad!",
            "",
            "error"
          );
        }
      } else {
        this.client.connect("", "", "session");
      }
    } else {
      var state = {
        formDisabled: "",
        logintext: "Bejelentkezés",
      };
      this.setState(state);
      this.client.disconnect(true);
      localStorage.removeItem("sid");
      setGlobal({ sid: 0 });
    }
  }

  loginWithAPI(username, password) {
    let fd = new FormData();
    fd.append('login', true);
    fd.append('username', username);
    fd.append('password', password);

    this.setState({ loading: true });
    axios.post(this.apiUrl + '/login', fd, { })
        .then(res => {
            if (res.data.login === 'ok') {
                this.setGlobal({ sid: res.data.sid });
                localStorage.setItem("sid", res.data.sid);
                this.client.connect("", "", "session");
                this.setState({ loading: false});
            } else {
                this.client.sendAlert(
                  res.data.message,
                  "",
                  "error"
                );
                this.setState({ loading: false });
            }
            this.setState({ loading: false });
        })
  }

  loginAnotherName() {
    localStorage.removeItem("sid");
    setGlobal({ sid: 0 });
  }

  _handleKeyDown = (e) => {
    if (e.key === "Enter") {
      this.connect();
    }
  };

  handleChange(name, value) {
    let user = Object.assign({}, this.state.user);
    user[name] = value;
    this.setState({ user: user });
  }

	shadeColor(color, percent) {
		var R = parseInt(color.substring(1,3),16);
		var G = parseInt(color.substring(3,5),16);
		var B = parseInt(color.substring(5,7),16);
	
		R = parseInt(R * (100 + percent) / 100);
		G = parseInt(G * (100 + percent) / 100);
		B = parseInt(B * (100 + percent) / 100);
	
		R = (R<255)?R:255;  
		G = (G<255)?G:255;  
		B = (B<255)?B:255;  
	
		var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
		var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
		var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));
	
		return RR+GG+BB;
	}

  render() {
    const mailto = "mailto:" + this.global.email;

    if (this.client.connected === false) {
      return (
        <div className="chatContainerFull Login" id="Login">
          <Scrollbar noScrollX={true}>
            
            <div id="login-box">


              <div className="login-logo">
                {this.global.themeMode === 'dark' &&
                <img src={logo} alt="TeamChat" title="TeamChat" />
                }

                {this.global.themeMode === 'light' &&
                <img src={logo_light} alt="TeamChat" title="TeamChat" />
                }

              </div>
              <form
                method="post"
                id="applogin"
                action=""
                className="login-form"
              >
                {this.client.connected === true && (
                  <div className="logined">
                    <strong>Belépve mint:</strong> {this.global.username}
                  </div>
                )}

                {this.client.connected === false && this.global.sid !== 0 && (
                  <div className="logined">
                    <strong>Folytatás mint:</strong> {this.global.username}{" "}
                  </div>
                )}

                

                {this.client.connected === false && this.global.sid === 0 && (
                  <div className="inputs">
                    <p className="usernameContainer">
                      <input
                        type="text"
                        name="username"
                        id="chatUsername"
                        value={this.state.username}
                        onChange={(e) => this.formChange(e, "username")}
                        placeholder="Írd be a chates neved"
                        disabled={this.state.formDisabled}
                      />
                    </p>
                    <p className="passwordContainer">
                      <input
                        type="password"
                        name="password"
                        id="chatPassword"
                        onKeyDown={this._handleKeyDown}
                        onChange={(e) => this.formChange(e, "password")}
                        placeholder="Írd be a chates jelszavad"
                        disabled={this.state.formDisabled}
                      />
                    </p>
                  </div>
                )}

                <input
                  type="button"
                  name="login"
                  id="chatLogin"
                  onClick={this.connect}
                  value={this.state.logintext}
                />

                {this.client.connected === false && this.global.sid !== 0 && (
                  <div className="loginAnotherName">
                    <input
                      type="button"
                      name="login"
                      onClick={this.loginAnotherName}
                      value="Belépés más néven"
                    />
                  </div>
                )}
              </form>

              <RegistrationModal />

              <p className="help">
                Nem sikerült belépni? <br /> Írj nekünk és segítünk:{" "}
                <a href={mailto}>{this.global.email}</a>
              </p>

              <ul className="badges">
                <li>
                  <a href="#g">
                    {this.global.themeMode === 'dark' &&
                    <img
                      src={googleplay_badge}
                      alt="Google Play"
                      title="Google Play"
                    />
                    }
                    {this.global.themeMode === 'light' &&
                    <img
                      src={googleplay_badge_light}
                      alt="Google Play"
                      title="Google Play"
                    />
                    }
                  </a>
                </li>
                <li>
                  <a href="#d">
                  {this.global.themeMode === 'dark' &&
                    <img
                      src={windows_badge}
                      alt="Letöltés windowsra"
                      title="Letöltés windowsra"
                    />
                  }

                  {this.global.themeMode === 'light' &&
                      <img
                      src={windows_badge_light}
                      alt="Letöltés windowsra"
                      title="Letöltés windowsra"
                    />
                  }

                  </a>
                </li>
              </ul>

              <ThemeSwitcher></ThemeSwitcher>   


              <p className="version">{this.global.version}</p>
              

            </div>
          </Scrollbar>
        </div>
      );
    } else {
      let vipColor = null;
      let userIcon = null;

      if (this.global.user.vipColor !== "") {

        if (this.global.themeMode === 'dark') {
          vipColor = { background: "#" + this.global.user.vipColor }
        } else {
          vipColor = { background: "#" + this.shadeColor('#'+this.global.user.vipColor,-25) }
        }

        userIcon = (
          <span className="userIcon icon-star" title="Kiemelt támogató"></span>
        );
      }

      let profileImage = null;
      var tabsDiv = document.querySelectorAll(".mainTabs .icon-login");

      if (
        this.global.user.profileImage !== undefined &&
        this.global.user.profileImage !== ""
      ) {
        profileImage = {
          backgroundImage: `url(${this.global.profileImageUrl}/${this.global.user.profileImage}.jpg)`,
        };

        tabsDiv[0].style.backgroundImage = `url(${this.global.profileImageUrl}/${this.global.user.profileImage}.jpg)`;
      } else {
        tabsDiv[0].style.backgroundImage = ``;
      }

      return (
        <div className="chatContainerFull Login" id="Login">
          <Scrollbar noScrollX={true}>
            
            <div id="login-box" className="loggedIn">
              
              <div id="applogin" className="login-form">
                <div className="profileImage" style={vipColor}>
                  <div className="profileimageContainer" style={profileImage}>
                    {this.state.profileImageLoaded === false && (
                      <Circle
                        color={"#ffffff"}
                        background="rgba(0,0,0,0.7)"
                        animation="fade"
                      />
                    )}
                  </div>
                  <label className="imageUpload">
                    <input type="file" onChange={this.uploadCoverImage} />
                    Módosítás <FontAwesomeIcon icon={faCloudUploadAlt} />
                  </label>

                  <div className="imageDelete">
                    {this.global.user.profileImage !== "" && (
                      <button
                        onClick={this.deleteCoverImage}
                        title="Profilkép törlése"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    )}
                  </div>
                </div>

                <div className="logined">
                  <strong>Belépve mint:</strong> {userIcon}{" "}
                  {this.global.username}
                </div>

                <DataModificationModal />
                <UploadProfileImageModal />
                <DeleteProfileModal />

                <input
                  type="button"
                  name="login"
                  id="chatLogin"
                  onClick={this.connect}
                  value={this.state.logintext}
                />
              </div>

              <p className="help">
                Kérdésed van?
                <br />
                Írj nekünk és segítünk: <a href={mailto}>{this.global.email}</a>
              </p>

              <ul className="badges">
                <li>
                  <a href="#g">
                  {this.global.themeMode === 'dark' &&
                    <img
                      src={googleplay_badge}
                      alt="Google Play"
                      title="Google Play"
                    />
                    }
                    {this.global.themeMode === 'light' &&
                    <img
                      src={googleplay_badge_light}
                      alt="Google Play"
                      title="Google Play"
                    />
                    }
                  </a>
                </li>
                <li>
                  <a href="#d">
                  {this.global.themeMode === 'dark' &&
                    <img
                      src={windows_badge}
                      alt="Letöltés windowsra"
                      title="Letöltés windowsra"
                    />
                  }

                  {this.global.themeMode === 'light' &&
                      <img
                      src={windows_badge_light}
                      alt="Letöltés windowsra"
                      title="Letöltés windowsra"
                    />
                  }
                  </a>
                </li>
              </ul>

              <ThemeSwitcher showText={true}></ThemeSwitcher>   


              <p className="version">{this.global.version}</p>
              

            </div>
            
          </Scrollbar>
        </div>
        
      );
    }
  }
}

//{ this.props.client.messages.room.map((tab,index) => (
//         <p>{tab.mess}</p>
//       ))
//}

export default Login;
