import React, { PureComponent, getGlobal, setGlobal } from "reactn";
import roomController from "../classes/roomController";
import RoomMessages from "./RoomMessages";
import RoomUsers from "./RoomUsers";
import RoomUsersWithProfileImage from "./RoomUsersWithProfileImage";
import { toArray } from "react-emoji-render";
import Picker from '@emoji-mart/react'
import data from '@emoji-mart/data/sets/14/twitter.json';


// import "emoji-mart/css/emoji-mart.css";
import Linkify from "react-linkify";
import Twemoji from "react-twemoji";
import EmojiConverter from "neato-emoji-converter";
import RoomSettingsModal from "../components/RoomSettingsModal";
import { MentionsInput, Mention } from "react-mentions";
import autosize from "autosize";
import clientStorage from "../classes/clientStorage";
import { isMobileOnly } from "react-device-detect";



class Room extends PureComponent {
  constructor(props) {
    super(props);

    this.client = clientStorage.id;
    this.roomID = props.roomID;
    var store = getGlobal();

    this.state = {
      data: [],
      message: "",
      focused: "focusout",
      showPicker: false,
      showRoomUserlist: true,
      hideNav: 0,
      settings: store[this.roomID + "Settings"],
      settingsOpened: false
    };
    this.roomController = new roomController(this.client);
    this.roomItem = this.roomController.getRoomItemByID(this.roomID);
    this._handleOnInputFocus = this._handleOnInputFocus.bind(this);
    this._handleOnInputFocusOut = this._handleOnInputFocusOut.bind(this);

    this.roomMessagesRef = React.createRef();
    this.chatroomUserlistRef = React.createRef();
    this.converter = new EmojiConverter();
    this.themeMode = getGlobal().themeMode;
    this.modernView = getGlobal().user.modernView;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.message !== prevState.message) {
      this.onInputSizeChange();
    } 
  }

  componentDidMount() {
    autosize(this.textarea);
    if (window.innerWidth <= 900) {
      this.setGlobal({
        showRoomUserlist: false,
      });
    }

    this.showRoomUserlistInit();
  }

  formChange(event, name) {
    if (name === "message") {
      this.setState({
        message: event.target.value,
      });
    }
  }

  onInputSizeChange() {
    let newStyle = 'calc(100% - '+(this.chatroomInput.clientHeight + 20)+'px)';
    let newStyleUserlist = 'calc(100% - '+(this.chatroomInput.clientHeight - 44)+'px)';
 
    if (this.roomMessagesDiv.style.height !== newStyle) {
      this.roomMessagesDiv.style.height= newStyle;
      this.chatroomUserlistRef.style.height= newStyleUserlist; 
      setTimeout(() => this.roomMessagesRef.scrollBottom(), 0);
    }
  }

  closeRoom() {
    this.client.sendMessage("room_part\x00" + this.roomID);
  }

  showRoomUserlistInit() {
    var messageContainer = document.querySelectorAll(
      ".chatroom .chatroom-messages"
    );
    var index = 0,
      length = messageContainer.length;
    if (this.global.showRoomUserlist === true) {
      for (; index < length; index++) {


          messageContainer[index].style.width = "calc(100% - 242px)";
      }
    } else {
      for (; index < length; index++) {
        messageContainer[index].style.width = "calc(100% - 0px)";
      }
    }
  }

  showRoomUserlist(initial) {
    var messageContainer = document.querySelectorAll(
      ".chatroom .chatroom-messages"
    );
    var index = 0,
      length = messageContainer.length;
    if (this.global.showRoomUserlist === true) {
      for (; index < length; index++) {
          messageContainer[index].style.width = "calc(100% - 0px)";
      }
      localStorage.setItem("showRoomUserlist", "disabled");
      this.setGlobal({
        showRoomUserlist: false,
      });
    } else {
      for (; index < length; index++) {
          messageContainer[index].style.width = "calc(100% - 242px)";        
      }
      localStorage.setItem("showRoomUserlist", "enabled");
      this.setGlobal({
        showRoomUserlist: true,
      });
    }
  }

  showPicker() {
    var show = false;
    if (this.state.showPicker === true) {
      show = false;
    } else {
      show = true;
    }
    this.setState({
      showPicker: show,
    });
  }

  hidePicker() {
    this.setState({
      showPicker: false,
    });
  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();

      if (isMobileOnly) {
        this.setState({
          message: this.state.message+"\n",
        });
      } else {
        this.sendMessage();
      }
    }
  };

  sendMessage() {
    if (this.state.message !== '') {
      this.client.sendMessage(
        "roommsg\x00" +
          this.roomID +
          "\x00" +
          this.converter.replaceUnicode(this.state.message)
      );
      this.setState({
        message: "",
        showPicker: false,
      });

      this.textarea.focus();
    }
  }

  _handleKeyDownTopic = (e) => {
    if (e.key === "Enter") {
        this.client.sendMessage(
          "chgtopic\x00" + this.roomID + "\x00" + e.target.value
        );
    }
  };

  _handleOnInputFocus() {
    this.setState({
      focused: "focusin",
      showPicker: false,
    });
    setTimeout(() => this.roomMessagesRef.scrollBottom(), 300);
  }

  _handleOnInputFocusOut() {
    this.setState({
      focused: "focusout",
    });
  }

  addEmoji = (e) => {
    let emoji = e.native;
    this.setState({
      message: this.state.message + emoji,
    });
  };

  processEmojiTopic(topic) {
    var componentDecorator = (href, text, key) => (
      <a href={href} key={key} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
    const processedMessage = [];
    const content = toArray(topic, { className: "smiley" });
    content.forEach((i) => {
      if (typeof i === "string") {
        processedMessage.push(
          <Linkify key={i} componentDecorator={componentDecorator}>
            {i}
          </Linkify>
        );
      } else {
        processedMessage.push(i);
      }
    });
    return processedMessage;
  }

  handleOpenModeratorModal() {
    setGlobal({ 
    moderatorModalOpened: true,
  }); 
  }

  render() {

      let mainClass = "chatroom " + this.roomID;

      var classChatroomMessages;
      if (this.state.focused === "focusin") {
        classChatroomMessages = "chatroom-messages chatroomFocused";
      } else {
        classChatroomMessages = "chatroom-messages";
      }

      var userlistVisiblity = "userlistVisiblityShow";
      if (this.global.showRoomUserlist === false) {
        userlistVisiblity = "userlistVisiblityHidden";
      }

      if (this.state.focused === "focusin") {
        userlistVisiblity = userlistVisiblity + " chatroomFocused";
      }

      let roomSettings = this.global[this.roomID + "Settings"];
      let topic = roomSettings.topic;

      let messageInput = "messageInput " + this.state.focused;

      let users = this.global[this.roomID + "Users"];
      
      let bgImage = {}

      if (roomSettings["bg"] !== '') {
        if (this.global.themeMode === 'dark') {          
          bgImage = roomSettings["bg"];
        } else {
          bgImage = roomSettings["bgLight"];
        }
      }

      return (
        <div className={mainClass}>
          <div
            className={classChatroomMessages}
            style={{ backgroundImage: `url(${bgImage})` }}
            onClick={() => this.hidePicker()}
            ref={(e) => (this.roomMessagesDiv = e)}

          >
            {topic !== "" &&
              topic !== "-" &&
              topic !== this.roomItem.text &&
              topic !== null && (
                <Twemoji options={{ className: "twemoji" }}>
                  <div className="topic">
                    <div className="content">
                      {this.processEmojiTopic(topic)}
                    </div>
                  </div>
                </Twemoji>
              )}
            <RoomMessages
              roomID={this.roomID}
              ref={(e) => (this.roomMessagesRef = e)}
            />
          </div>

          {/* {this.global.showRoomUserlist === true && (
            <button
              id="showUserlist"
              className="userlistHide userlistButton"
              onClick={() => this.showRoomUserlist()}
            ></button>
          )}

          {this.global.showRoomUserlist === false && (
            <button
              id="showUserlist"
              className="userlistShow userlistButton"
              onClick={() => this.showRoomUserlist()}
            ></button>
          )} */}

          <div className={`userlistContainer ${userlistVisiblity}`} ref={c => (this.chatroomUserlistRef = c)}>
           {this.global.user.modernView === true && (
            <RoomUsersWithProfileImage roomID={this.roomID} right={roomSettings.right}/>
           )}

          {this.global.user.modernView === false && (
            <RoomUsers roomID={this.roomID} right={roomSettings.right} />
           )}

          </div>

          <div className="chatroom-input" ref={c => (this.chatroomInput = c)}>
            {this.state.showPicker === true && (
              <div className="emojiSelector">
                <Picker
                  data={data}
                  onEmojiSelect={this.addEmoji}
                  emojiSize={18}
                  set="twitter"
                  title=""
                  i18n={{
                    search: "Keresés",
                    notfound: "Nincs találat!",
                    clear: "Törlés",
                    categories: {
                      search: "Keresési eredmények",
                      recent: "Gyakran használt",
                      smileys: "Hangulatjelek",
                      people: "Hangulatjelek",
                      nature: "Állatok és természet",
                      foods: "Étel és ital",
                      activity: "Tevékenység",
                      places: "Helyek és utazás",
                      objects: "Tárgyak",
                      symbols: "Szimbólumok",
                      flags: "Zászlók",
                      custom: "Egyéb",
                    },
                    skins: {
                      choose: "Válassz",
                      tone: "Bőrszín",
                    },
                  }}
                />
              </div>
            )}
            <button className="showPicker" onClick={() => this.showPicker()} />

            {Number(roomSettings.right) === 0 && (
              <MentionsInput
                allowSpaceInQuery={true}
                inputRef={c => (this.textarea = c)} 
                disabled={roomSettings.locked ? "disabled" : ""}
                className={messageInput}
                placeholder={
                  roomSettings.locked
                    ? "A szoba le van zárva"
                    : "Üzenet küldése..."
                }
                value={this.state.message}
                onKeyDown={this._handleKeyDown}
                onFocus={this._handleOnInputFocus}
                onBlur={this._handleOnInputFocusOut}
                onChange={(e) => this.formChange(e, "message")}
              >
                <Mention trigger="@" data={users} markup="@[__display__]" />
              </MentionsInput>
            )}

            {Number(roomSettings.right) === 1 && (
              <MentionsInput
                allowSpaceInQuery={true}
                inputRef={c => (this.textarea = c)} 
                className={messageInput}
                placeholder={
                  roomSettings.locked
                    ? "A szoba le van zárva"
                    : "Üzenet küldése..."
                }
                value={this.state.message}
                onKeyDown={this._handleKeyDown}
                onFocus={this._handleOnInputFocus}
                onBlur={this._handleOnInputFocusOut}
                onChange={(e) => this.formChange(e, "message")}
              >
                <Mention trigger="@" data={users} markup="@[__display__]" />
              </MentionsInput>
            )}

            <RoomSettingsModal
              roomID={this.roomID}
              right={roomSettings.right}
            />
            <button
              className="users"
              onClick={() => this.showRoomUserlist()}
            />
            <button
              className="sendMessage"
              onClick={() => this.sendMessage()}
            />
          </div>
        </div>
      );
  
  }
}
export default Room;
