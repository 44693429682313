import { getGlobal, setGlobal } from 'reactn';
import md5 from 'js-md5';
import shortid from "shortid";
import addNotification from 'react-push-notification';
import ignoresController from './ignoresController';

export default class roomController {
	constructor(chatClient) {
		this.chatClient = chatClient;
		this.ignoresController = new ignoresController(this.chatClient);
	}

	roomAdd(res) {
		this.chatClient.setGlobal({
			[res[1] + 'Messages']: [],
			[res[1] + 'Users']: [],
		})

		this.appendServerMessage(res[1], 'Beléptél a szobába.', shortid.generate(), getGlobal().userJoinColor);
	}

	roomAddTab(roomid) {
		var roomItem = this.chatClient.roomListController.getRoomItemByID(roomid);
		this.chatClient.tabController.addTab('room', roomItem['text'], roomid, 'roomTab ' + roomItem['iconCls']);
		//this.updateRoomState(roomid, 'opened');
	}

	appendMessage(roomid, username, color, rmessage, profileImage, vipColor, messageID) {
		if (this.ignoresController.userInIgnoreList(username)) {
		  return;
		}
	  
		const store = getGlobal();
		const roomMessages = store[roomid + 'Messages'];
		const roomIndex = this.getRoomIndexByID(roomid);
	  
		if (this.chatClient.state.selectedIndex !== roomIndex) {
		  if (!this.chatClient.tabController.checkTabColorIsHighlighted(roomIndex)) {
			this.chatClient.tabController.changeColorByIndex(roomIndex, store.tabNewMessageColor);
		  }
		}
	  
		const currentLength = roomMessages.length;
		const messageLimit = store.roomMessageLimit;
	  
		if (currentLength >= messageLimit) {
		  const delLines = currentLength - messageLimit;
		  roomMessages.splice(0, delLines);
		}
	  
		const messageItem = {
		  id: messageID,
		  roomid,
		  username,
		  userid: md5(username),
		  message: rmessage,
		  color,
		  time: getGlobal().serverTime,
		  serverMessage: false,
		  profileImage,
		  vipColor,
		};
	  
		if (username !== getGlobal().username && rmessage.includes(`@[${getGlobal().username}]`)) {
		  const roomItem = this.getRoomItemByID(roomid);
		  addNotification({
			title: getGlobal().appName,
			subtitle: `${username} keresett a ${roomItem['text']} szobában!`,
			message: `${username} keresett a ${roomItem['text']} szobában!`,
			theme: 'darkblue',
			native: true,
		  });
		  setTimeout(() => this.chatClient.playMentionNotification());
	  
		  if (this.chatClient.state.selectedIndex !== roomIndex) {
			this.chatClient.notificationsController.add(`${username} keresett a ${roomItem['text']} szobában!`, true);
			this.chatClient.tabController.changeColorByIndex(roomIndex, store.tabHighlightColor);
		  }
		}
	  
		roomMessages.push(messageItem);
	  
		this.chatClient.setGlobal({
		  [roomid + 'Messages']: roomMessages,
		  [roomid + 'Users']: store[roomid + 'Users'],
		});
	  }
	  

	appendServerMessage(roomid, rmessage, messageID, color = '') {
		const store = getGlobal();
		const roomMessages = store[roomid + 'Messages'];
		
		const currentLength = roomMessages.length;
		const messageLimit = store.roomMessageLimit;
		
		if (currentLength >= messageLimit) {
			roomMessages.splice(0, currentLength - messageLimit);
		}
		
		const messageItem = {
			id: messageID,
			roomid,
			username: '',
			userid: '',
			message: rmessage,
			color,
			time: getGlobal().serverTime,
			serverMessage: true,
		};
		
		roomMessages.push(messageItem);
		this.chatClient.setGlobal({ [roomid + 'Messages']: roomMessages });
	}
	  

	processUsers(roomid, array) {
		const store = getGlobal();
		const roomUsers = [...store[roomid + 'Users']];
		const newUserItems = array
		  .map(data => data.split('\x01'))
		  .filter(([name, age, sex, state, icon, profileImage], i) => name !== '' && i > 1)
		  .map(([name, age, sex, state, icon, profileImage]) => {
			const sexSplit = sex.split(':');
			return {
			  id: shortid.generate(),
			  name,
			  display: name,
			  sex: sexSplit[0],
			  age,
			  state,
			  icon,
			  profileImage,
			};
		  });
		const updatedRoomUsers = [...roomUsers, ...newUserItems];
		this.chatClient.setGlobal({
		  [roomid + 'Users']: updatedRoomUsers,
		});
	  }
	  

	appendUser(roomid, age, name, sex, state, icon, profileImage) {
		const store = getGlobal();
		const roomUsers = [...store[roomid + 'Users']];
		const userItem = {
			id: shortid.generate(),
			name: name,
			display: name,
			userid: sex.split(':')[1],
			sex: sex.split(':')[0],
			age: age,
			state: state,
			icon: icon,
			profileImage: profileImage
		};
		roomUsers.push(userItem);
		const newStore = { ...store, [roomid + 'Users']: roomUsers };
		this.chatClient.setGlobal(newStore);
	
		const currentUser = getGlobal().user;
		if (currentUser.joins && currentUser.username !== name) {
			this.appendServerMessage(roomid, `${name} belépett a szobába`, shortid.generate(), currentUser.userJoinColor);
		}
	}
	

	removeUser(roomid, age, name, sex, type) {
		const store = getGlobal();
		const roomUsers = store[roomid + 'Users'];
		const newRoomUsers = roomUsers.filter(user => user.name !== name);

		this.chatClient.setGlobal({
			[roomid + 'Users']: newRoomUsers
		});

		const userJoins = store.user.joins;
		const isCurrentUser = store.username !== name;

		if (userJoins && isCurrentUser) {
			if (type === 'disconnected') {
				this.appendServerMessage(roomid, `${name} kilépett`, shortid.generate(), store.userExitColor);
			} else {
				this.appendServerMessage(roomid, `${name} kilépett a szobából`, shortid.generate(), store.userPartColor);
			}
		}
	}


	roomSettings(res) {
		let item = {
			id: shortid.generate(),
			right: parseInt(res[2]),
			locked: parseInt(res[3]),
			topicOpened: parseInt(res[4]),
			bg: res[5],
			bgLight: res[6],
			autoConnect: parseInt(res[7]),
			topic: res[8]
		};

		this.chatClient.setGlobal({
			[res[1] + 'Settings']: item
		});
	}

	getRoomItemByID(roomid) {
		let rooms = getGlobal().rooms.slice();
		for (var key in rooms) {
			var roomData = rooms[key];
			for (var rdKey in roomData) {
				var roomDataItem = roomData[rdKey];
				for (var cKey in roomDataItem) {
					var roomItem = roomDataItem[cKey];
					if (roomItem['id'] === roomid) {
						return roomItem;
					}
				}
			}
		}
	}

	getRoomIndexByID(roomid) {
		let data = this.chatClient.state.data.slice();
		var tabIndex = null;
		for (var key in data) {
			if (data[key]['content'] === roomid) {
				tabIndex = parseInt(key);
				break;
			}
		}
		return tabIndex;
	}

	lastRoomIndex() {
		let data = this.chatClient.state.data.slice();
		var tabIndex = null;
		for (var key in data) {
			if (data[key]['module'] === 'room') {
				tabIndex = parseInt(key);
			}
		}
		return tabIndex;
	}

	setTopic(roomid, topic, user) {
		var store = getGlobal();
		let roomSettings = store[roomid + 'Settings'];

		roomSettings['topic'] = topic;
		roomSettings['topicUser'] = user;

		setGlobal({
			[roomid + 'Settings']: roomSettings
		});

	}


	reconnectRooms() {
		let data = this.chatClient.state.data.slice();
		for (var key in data) {
			if (data[key]['module'] === 'room') {
				this.chatClient.sendMessage("room_join\x00" + data[key]['content']);
			}
		}
	}

	clearRooms() {
		let data = this.chatClient.state.data.slice();
		for (var key in data) {
			if (data[key]['module'] === 'room') {
				this.chatClient.setGlobal({
					[data[key]['content'] + 'Messages']: [],
					[data[key]['content'] + 'Users']: [],
					// [data[key]['content'] + 'Settings']: []
				})
			}
		}
	}

	freezeRooms() {
	const data = this.chatClient.state.data;
	const freezed = getGlobal().freezedRooms;
	const newFreezedRooms = [];
	
		for (let key in data) {
			if (data[key].module === 'room' && !this.isFreezedRoom(data[key].content)) {
				newFreezedRooms.push({ id: data[key].content });
			}
		}
	
		if (newFreezedRooms.length > 0) {
			this.chatClient.setGlobal({
				freezedRooms: [...freezed, ...newFreezedRooms]
			});
		}
	} 


	isFreezedRoom(roomid) {
		const data = getGlobal().freezedRooms;
		for (let i = 0; i < data.length; i++) {
			if (data[i]['id'] === roomid) {
				return true;
			}
		}
		return false;
	}


	delFreezedRoom(roomid) {
		const data = getGlobal().freezedRooms;
		for (const [key, value] of data.entries()) {
			if (value.id === roomid) {
				data.splice(key, 1);
				break; // hagyjuk abba a ciklust, ha megtaláltuk az elemet
			}
		}

		try {
			this.chatClient.setGlobal({
				freezedRooms: data
			});
		} catch (err) {
			console.log('delFreezedRoom: ' + err.message);
		}
	}


}