import React, { PureComponent } from 'reactn';
import Scrollbar from 'react-scrollbars-custom';
import clientStorage from '../classes/clientStorage'
import Popup from "reactjs-popup";
import axios from 'axios';
import { Circle } from 'react-preloaders';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUploadAlt, faTrash } from '@fortawesome/free-solid-svg-icons'
import uploadimage from '../images/uploadimage.svg';
import { SRLWrapper } from "simple-react-lightbox";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import 'reactjs-popup/dist/index.css';

class UploadProfileImageModal extends PureComponent {

    constructor(props) {
        super(props);
        this.client = clientStorage.id;
        this.handleOpenModal = this.handleOpenModal.bind(this);

        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.changeStep = this.changeStep.bind(this);
        this.uploadProfileImage = this.uploadProfileImage.bind(this);
        this.deleteImage = this.deleteImage.bind(this);

        this.apiUrl = this.global.apiUrl;

        this.state = {
            currentStep: 1,
            errors: [],
            loading: true,
            uploaded: true,
            images: []
        }
    }

    changeStep(step) {
        this.setState({ currentStep: step });
    }

    getUserData() {
        let fd = new FormData();
        fd.append('getProfileImages', true);
        fd.append('sid', this.global.sid);
        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        this.setState({ errors: {} });
        axios.post(this.apiUrl + '/getProfileImages', fd, { headers })
            .then(res => {
                if (res.data.getProfileImages === 'ok') {
                    this.setState({ loading: false, images: res.data.images });
                } else {
                    this.setState({ loading: false });
                }
            })
    }

    deleteImage(id) {
        let fd = new FormData();
        fd.append('deleteProfileImage', true);
        fd.append('sid', this.global.sid);
        fd.append('id', id);

        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        this.setState({ errors: {} });
        axios.post(this.apiUrl + '/deleteProfileImage', fd, { headers })
            .then(res => {
                if (res.data.deleteProfileImage === 'ok') {
                    this.getUserData();
                }
            })
    }

    handleOpenModal() {
        this.getUserData();
        this.setState({ opened: true, currentStep: 1, errors: [] });
    }

    handleCloseModal() {
        this.setState({ opened: false, smscode: '', password: '', password2: '' });
    }


    uploadProfileImage(event) {
        event.preventDefault();
        try {
            let file = event.target.files[0];
            let fd = new FormData();
            fd.append('uploadProfileImage', true);
            fd.append('sid', this.global.sid);
            fd.append("image", file, file.name);
            fd.append("method", 'upload');

            const headers = {
                'Content-Type': 'multipart/form-data'
            };
            this.setState({ loading: true });

            this.setState({ profileImageLoaded: false });
            axios.post(this.apiUrl + '/uploadProfileImage', fd, { headers }, {
                onUploadProgress: progressEvent => {
                }
            })
                .then(res => {
                    if (res.data.uploadProfileImage === 'success') {
                        this.getUserData();
                    } else if (res.data.uploadProfileImage === 'error') {
                        this.client.sendAlert('Figyelem!', res.data.message, 'warning');
                    }
                    this.setState({ loading: false });
                });
        }
        catch (err) {
            console.log('uploadProfileImage: ' + err.message);
        }
    }

    deleteSubmit = (id) => {
        confirmAlert({
            title: 'Biztosan törlöd a képet?',
            message: 'A képet véglegesen töröljük.',
            buttons: [
                {
                    label: 'Igen',
                    onClick: () => this.deleteImage(id)
                },
                {
                    label: 'Nem',
                }
            ]
        });
    };

    render() {
        const buttons = <input type="button" onClick={this.handleOpenModal} value="Képek feltöltése az adatlapodra" />
        if (this.state.currentStep === 1) {
            return (
                <div>
                    {buttons}
                    <Popup open={this.state.opened} closeOnDocumentClick={true} onClose={this.handleCloseModal}>
                        {close => (
                            <div className="modal">

                                <button className="close" onClick={close} />
                                <div className="header">Képek feltöltése az adatlapodra</div>
                                <Scrollbar noScrollX={true}>
                                    <div className="UploadProfileImage">
                                        <div className="toolbar">
                                            <label className="imageUpload">
                                                <input type="file" onChange={this.uploadProfileImage} />
                                                Új kép feltöltése <FontAwesomeIcon icon={faCloudUploadAlt} />
                                            </label>
                                        </div>


                                        {this.state.loading === true &&
                                            <Circle color={'#54a6e0'} background="rgba(0,0,0,1)" animation="fade" />
                                        }

                                        {this.state.loading === false &&

                                            <div className="imageList">
                                                {this.state.images.length === 0 &&
                                                    <div className="image notfound"><img src={uploadimage} alt="Tölts fel képet!" title="Tölts fel képet!" /></div>
                                                }
                                                <SRLWrapper>
                                                    <ul>
                                                        {
                                                            this.state.images.map((arr, index) => {
                                                                return <li key={arr.id}><a href={arr.original}><img src={arr.thumbnail} alt="" /></a><button onClick={() => this.deleteSubmit(arr.id)} title="Törlés"><FontAwesomeIcon icon={faTrash} /></button></li>
                                                            })
                                                        }
                                                    </ul>
                                                </SRLWrapper>

                                            </div>
                                        }
                                    </div>
                                </Scrollbar>
                            </div>
                        )}

                    </Popup>
                </div>
            );
        }
    }
}


export default UploadProfileImageModal;