import React, { PureComponent, setGlobal } from 'reactn';
import Scrollbar from 'react-scrollbars-custom';
import clientStorage from '../classes/clientStorage'
import Popup from "reactjs-popup";
import axios from 'axios';
import { SRLWrapper } from "simple-react-lightbox";
import { Circle } from 'react-preloaders';
import noimage from '../images/noimage.svg';
import error from '../images/error.svg';
import 'reactjs-popup/dist/index.css';


class ProfileModal extends PureComponent {

    constructor(props) {
        super(props);
        this.client = clientStorage.id;
        this.handleOnOpen = this.handleOnOpen.bind(this);
        this.privateOpen = this.privateOpen.bind(this);
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.apiUrl = this.global.apiUrl;

        this.state = {
            loading: true,
            profileImage: null,
            userData: [{ images: [] }],
            vipColor: null,
            userIcon: null,
            notLoaded: false
        }
    }

    handleOpenModal() {
        setGlobal({ profileModalOpened: true });
    }

    handleCloseModal() {
        setGlobal({ profileModalOpened: false });
    }

    handleOnOpen() {
        this.getUserData();
    }

    privateOpen(e, name) {
        this.handleCloseModal();
        this.client.sendMessage("private_join\x00" + name);
    }

    handleOpenModeratorModal() {
      this.handleCloseModal();
        setGlobal({ 
        moderatorModalOpened: true,
        moderatorModalOpenedUser: this.global.profileModalOpenedUser,
      });
    }

    getUserData() {
        let userData;
        let fd = new FormData();
        fd.append('getProfile', true);
        fd.append('sid', this.global.sid);
        fd.append('username', this.global.profileModalOpenedUser);

        const headers = {
            'Content-Type': 'multipart/form-data'
        };
        this.setState({ loading: true });
        axios.post(this.apiUrl + '/getProfile', fd, { headers })
            .then(res => {

                if (res.data.getProfile === 'ok') {
                    userData = JSON.parse(res.data.userData);

                    let vipColor = null;
                    let userIcon = null;

                    if (userData.vipColor !== '') {
                        vipColor = { background: "#" + userData.vipColor,}
                        userIcon = <span className="userIcon icon-star" title="Kiemelt támogató"></span>
                    }

                    this.setState({ loading: false, notLoaded: false, userData: userData, profileImage: userData.profileImage.thumbnail, vipColor: vipColor, userIcon: userIcon });
                } else {
                    this.setState({ loading: false, notLoaded: true });
                }
                this.setState({ loading: false });
            })
    }

    render() {

        return (
            <Popup open={this.global.profileModalOpened} closeOnDocumentClick={true} onClose={this.handleCloseModal} onOpen={this.handleOnOpen} className="profileModalPopup">
                {close => (

                    <div className="modal profileModal">
                        <button className="close" onClick={close} />

                        {this.state.notLoaded === false &&

                            <div style={{ position: 'relative', height: '100%' }}>
                                <div className="header">
                                    <div className="profileImage" style={this.state.vipColor}>
                                        {typeof this.state.profileImage !== 'undefined' &&
                                            <div className="profileimageContainer" style={{ backgroundImage: `url(${this.state.profileImage})` }}></div>
                                        }

                                        {typeof this.state.profileImage === 'undefined' &&
                                            <div className="profileimageContainer"></div>
                                        }
                                    </div>

                                    {this.state.loading === false &&
                                        <div className="logined">
                                            {this.state.userIcon} {this.global.profileModalOpenedUser}
                                            <div className="buttons">
                                                <button onClick={(e) => this.privateOpen(e, this.global.profileModalOpenedUser)}><span className="buttonIcon icon-private" title="Privát üzenet"></span> Privát üzenet </button>
                                                {this.global.moderator === true &&
                                                <button className="moderator"  onClick={() => this.handleOpenModeratorModal()}><span className="buttonIcon icon-moderator" title="Moderálás"></span> Moderálás</button>
                                                }                                            
                                            </div>
                                        </div>
                                    }

                                </div>
                                <Scrollbar noScrollX={true} removeTracksWhenNotUsed={false}>
                                    <div className="content">
                                        {this.state.loading === true &&
                                            <Circle color={'#54a6e0'} background="rgba(0,0,0,1)" animation="fade" />
                                        }

                                        {this.state.loading === false &&
                                            <div className="userDatas">

                                                <p><label>Név:</label> {this.state.userData.name}</p>
                                                <p><label>Lakhely:</label> {this.state.userData.residence}</p>
                                                <p><label>Születésnap:</label> {this.state.userData.birth}</p>

                                                {this.state.userData.email &&
                                                    <p><label>E-mail:</label> <a href={`mailto: ${this.state.userData.email}`}> {this.state.userData.email}</a></p>
                                                }

                                                {this.state.userData.telephone &&
                                                    <p><label>Telefonszám:</label> {this.state.userData.telephone}</p>
                                                }

                                                {this.state.userData.chattime &&
                                                    <p><label>Elchatelt idő:</label> {this.state.userData.chattime}</p>
                                                }

                                                <p><strong>Bemutatkozás:</strong><br /><br />{this.state.userData.introduction}</p>

                                                <h3>Feltöltött képei:</h3>
                                                <div className="imageList">
                                                    {this.state.userData.images.length === 0 &&
                                                        <div className="image notfound"><img src={noimage} alt="Még nem töltött fel képet!" title="Még nem töltött fel képet!" /></div>
                                                    }
                                                    <SRLWrapper>
                                                        <ul>
                                                            {
                                                                this.state.userData.images.map((arr, index) => {
                                                                    return <li key={arr.id}><a href={arr.original}><img src={arr.thumbnail} alt="" /></a></li>
                                                                })
                                                            }
                                                        </ul>
                                                    </SRLWrapper>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Scrollbar>
                            </div>
                        }

                        {this.state.notLoaded === true &&
                            <div className="notLoaded">
                                <div className="header">Nem sikerült betölteni a felhasználó adatlapját!</div>
                                <div className="image error"><img src={error} alt="Még nem töltött fel képet!" title="Még nem töltött fel képet!" /></div>
                            </div>
                        }

                    </div>
                )}
            </Popup>
        );

    }
}


export default ProfileModal;